.watch-cnt{
    display: flex;
    align-items: center;
    flex-direction: column;
}

.watch-video{
    height: 60vh;
    
    border: 2px solid black;
}

.back-btn{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.back-btn button{
    padding: 0.7vh 2vh;
    border-radius: 6px;
    border: none;
    background-color: var(--prime-color);
    color: white;
    letter-spacing: 1px;
    cursor: pointer;
}


@media (max-width: 1080px) {
    .watch-cnt{
        margin: 0;
    }
    .watch-video{
        width: 100%;
        height: 100%;
    }  
    .back-btn{
        display: flex;
        justify-content: space-between;
        gap: 10px;
    }
    .back-btn button:hover{
        background-color: brown;
        transition: 0.2s;
    }
    .back-btn button{
        margin-bottom: 2vh;
    }
    .watch-video::-webkit-media-contrr{
        /* display: none; */
      }
}