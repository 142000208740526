.detail , .movie {
  width: 100% - 10vh;
  height: 50dvh;
 
  background-color: wheat;
  overflow-y: hidden;
  padding: 3vh;
  border-radius: 10px;
  display: flex;
  gap: 40px;
  color: black;
}

.series-dl{
  margin: 10vh;
  min-height: 120vh;
  padding-bottom: 10vh ;
}

.img img{
    height: 100%;
    border-radius: 10px;
    box-shadow: 1px 1px 30px rgb(188, 169, 93);
}

.episodes-list{
  margin-top: 2vh;
  margin-bottom: 5vh;

}

.episodes-list ul{
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 5vh
}


  .episodes-list ul a{
  background-color: white;
  text-align: none;
  border: 1px solid black;
  height: 25px;
  text-decoration: none;
  color: black;
  font-weight: 600;
  text-align: center;
  border-radius: 8px;
  width: 70px;
  height: 35px;
  padding-top: 4px;
}


/* *****************break out******************* */
/********************************/

@media (max-width: 850px) {
  .series-dl{
    margin: 1vh 0vh;
  }
  .detail, .movie{
    width: fit-content;
    display: grid;
    height: auto;
    margin-top: 0;
    margin: 0vh 0vh;
    gap: 2px;
  }

  .img img{
    height: 98%;
    width: 100%;
  }
}