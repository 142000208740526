.app {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bg-fixed-img {
  background-image: url("./img/bgimg.jpg");
  height: 100dvh;
  width: 100%;
  background-size: cover;
  background-position: center;
  filter: brightness(0.9) contrast(1);
  overflow: visible;
  z-index: -10;
  position: fixed;
  top: 0;
  left: 0;
  backdrop-filter: blur(3px);
}

.bg-fixed-base {
  margin:  2vh 4vh ;
  border-radius: 15px;
  backdrop-filter: blur(13px);
  width: 96%;
  height: 96vh;
  position: absolute;
  top: 0;
  overflow-y: scroll;
  box-shadow: 1px 1px 30px rgba(156, 156, 156, 0.652);
}

.contents {
  position: relative;
  z-index: 1;
  height: 100%;
  padding: 0vh 10vh;
  color: rgb(255, 255, 255);
}

@media (max-width: 1000px) {
    .bg-fixed-base{
        margin: 2vh 1vh 2vh 1vh;
        width: 95.5%;
    }
    .contents{
        padding: 0 2vh;
    }
}