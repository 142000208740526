.home {
  height: auto;
  width: 100%;
  padding: 2vh 0 5vh 0;
}

.animesMovie {
  display: flex;
  overflow-x: auto; /* Enable horizontal scrolling */
  padding: 10px;
}

.outer-box {
  min-width: 150px; /* Adjust width as needed */
  margin-right: 10px; /* Spacing between cards */
  flex: 0 0 auto; /* Prevent shrinking */
}


.maiwe {
  padding: 0 10vh;
  margin-top: 10vh;
}

.single-movie {
  height: 50vh;
  padding: 3vh;
  width: 100%;
  display: flex;
  background-color: wheat;
  gap: 40px;
  border-radius: 10px;
  color: black;
  opacity: 0.8;
}

.single-movie img {
  box-shadow: 2px 2px 20px rgb(205, 166, 94);
  border-radius: 10px;
  height: 100%;
  width: auto;
}

.wrt-cont-mg {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 4vh 0 0;
}
.title-wrt h1 {
  font-size: 50px;
  overflow-y: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.title-wrt h2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  padding-left: 1vh;
  color: #333;
  text-transform: capitalize;
  font-size: 24px;
  padding-bottom: 1vh;
}

.des-wrt .hid p {
  padding-left: 5px;
  font-size: 18px;
  overflow-y: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  word-spacing: 3px;
  letter-spacing: 1px;
  text-align: justify;
  line-height: 24px;
  margin-bottom: 2vh;
}

.ant-wrttt {
  display: flex;
  padding-left: 2px;
  gap: 5px;
}

.ant1 {
  background-color: #ffffff;
}

.aat {
  padding: 4px 15px;
  border-radius: 6px;
}
.ant2 {
  background-color: #fbd37e;
}
.ant3 {
  background-color: #9be39a;
}
.btns-wrt {
  width: 100%;
  display: flex;
  gap: 20px;
  padding-bottom: 2px;
  margin-top: 2vh;
}
.btns-wrt button {
  font-size: 16px;
  height: 40px;
  padding: 0 2vh;
  border-radius: 25px;
  border: none;
  background-color: rgb(204, 142, 27);
}
.btns-wrt button a {
  color: black;
  text-decoration: none;
}
.btns-wrt button:nth-child(2n) {
  background-color: whitesmoke;
}

/* ******* */

.Movies {
  margin-top: 10vh;
}
.heading h1 {
  color: white;
  text-shadow: 2px 2px black;
}
.heading hr {
  width: 60px;
  height: 4px;
  background-color: white;
  border: none;
}
.animesMovie,
.animesSeries {
  display: flex;
  gap: 30px;
  overflow-x: scroll;
}
.animesMovie a,
.animesSeries a {
  text-decoration: none;
}
.innerbox {
  box-shadow: 1px 1px 15px rgba(231, 192, 141, 0.636);
  width: 245px;
  height: 88%;
  border-radius: 10px;
}
.innerbox img {
  border-radius: 10px;
  width: 100%;
  height: 100%;
}
.outer-box p {
  padding: 1vh 0 0 3px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  color: white;
}

/* ****************************************************** */
/* ****************media breakout change***************** */
/* ****************************************************** */

@media (max-width: 860px) {
  .maiwe {
    padding: 3vh;
    margin-top: -2vh;
  }
  .single-movie {
    height: auto;
    display: grid;
    padding: 2vh;
    gap: 5px;
  }
  .single-movie img {
    width: 100%;
    height: 99%;
  }
  .wrt-cont-mg {
    padding: 0;
    margin-top: -1vh;
  }
  .title-wrt h1 {
    font-size: 24px;
    -webkit-line-clamp: 2;
  }
  .title-wrt h2{
  -webkit-line-clamp: 2;
  font-size: 15px;
  padding-left: 0;
  }
  .des-wrt .hid p{
    padding-left: 0;
  -webkit-line-clamp: 3;
  font-size: 13px;
  line-height: normal;
  }
  .aat {
    padding: 3px 10px;
  }
  .btns-wrt button{
    font-size: 12px;
  }
  .innerbox{
    width: 210px;
  }
}
