.navBar {
    width: 100%;
    display: inline-flex;
    align-items: center;
}

.lp {
    height: 12vh;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.left-lp {
    display: flex;
    align-items: center;
    gap: 45px;
}

.logo-lp h1 {
    font-size: 34px;
    font-family: cursive;
}

.links-lp {
    display: flex;
    align-items: center;
    gap: 20px;
}

.links-lp a {
    font-weight: 600;
    color: white;
    font-size: 16px;
    text-decoration: none;
}

.links-lp a:hover {
    transform: scale(1.2);
    transition: 0.2s ease-in;
    color: var(--prime-color-light);
}

.right-lp {
    display: flex;
    align-items: center;
    gap: 20px;
}

.search-lp input {
    border-radius: 40px;
    outline: none;
    border: none;
    height: 34px;
    width: 240px;
    color: white;
    padding: 0 2vh;
    background-color: #3333339f;
    box-shadow: 1px 1px 50px rgba(162, 162, 162, 0.507);
}

.search-lp input::placeholder {
    color: rgb(176, 176, 176);
}

.login-lp img {
    /* box-shadow: ; */
    cursor: pointer;
    filter: drop-shadow(1px 1px 10px rgba(255, 255, 255, 0.726));
}

.login-lp button:hover {
    transform: scale(1.1);
    opacity: 0.7;
    transition: 0.2s;
}

/* Mobile view styles */
.ph {
    display: none;
}

@media (max-width: 890px) {
    .lp {
        display: none;
    }

    .ph {
        display: flex;
        height: 8vh;
        width: 100%;
        align-items: center;
        justify-content: space-between;
    }

    .left-ph {
        display: flex;
        align-items: center;
        gap: 14px;
    }

    .left-ph h1 {
        padding-top: 3px;
    }

    .left-ph img{
        cursor: pointer;
    }
    .left-ph img:hover{
        opacity: 0.5;
    }
    .right-ph {
        display: flex;
        align-items: center;
        gap: 10px;
    }
    .right-ph img{
        cursor: pointer;
    }
    .right-ph img:hover{
        opacity: 0.5;
    }
    .sideNav{
        position: absolute;
        background-color: #333;
        height: 100%;
        width: 100%;
        bottom: 0;
        left: 0;
        padding: 1.5vh;
        z-index: 1;
        animation: sideBarSlide 0.3s ease-in-out;
    }

    @keyframes sideBarSlide {
        from{
            left: -100%;
        }
        to{
            left: 0%;
        }
    }
    .up-part img{
        cursor: pointer;
    }
    .dn-part{
        margin-top: 5vh;
    }
    .link{
        display: grid;
        gap: 24px;
    }
    .lnk{
        display: flex;
        align-items: center;
        gap: 12px;
    }
    .lnk a{
        color: gray;
        font-weight: 600;
        font-size: 18px;
        text-decoration: none;
    }
    .lnk a:hover{
        opacity: 0.7;
    }
    .searchBox{
        margin-top: 5vh;
        padding: 0 5%;
    }
    .searchbox input{
        width: 100%;
        height: 32px;
        border-radius: 50px;
        border: none;
        outline: none;
        padding: 0 2vh;
    }
}   





/* searching feature */

.box-search{
    position: relative;
}

.results{
    position: absolute;
    background-color: #201f1f;
    margin-top: 3vh;
    width: 320px;
    max-height: 80dvh;
    display: grid;
    gap: 5px;
    z-index: 100;
    box-shadow: 1px 1px 20px rgb(78, 76, 76);
    border-radius: 10px;
    padding: 2vh;
    overflow-y: scroll;
}

.results a{
    height: 55px;
    width: 100%;
    background-color: #333;
    position: relative;
}

.results a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    
  
}

.results a img{
    height: 100%;
    width: 45px;
}

.results a p{
    text-decoration: none;
    color: white;
    padding: 0 1vh 0 0;
    font-size: 15px;
}


@media (max-width: 750px) {
    .results a {
        justify-content: space-between;
       }
       .results a img{
        margin-left: 0;
       }
       .results{
        width: 100%;
       }

}