.login{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.login form{
    display: flex;
    gap: 15px;
    flex-direction: column;
    height: fit-content;
    align-items: center;
    width: 300px;
    background-color: #333;
    margin-bottom: 25vh;
    padding: 2vh;
    border-radius: 10px
}
.login form h1{
    margin-bottom: 2vh;
}

.login form input{
    height: 35px;
    width: 100%;
    border-radius: 7px;
    border: none;
    padding: 0 2vh;
}

.login form input[type=submit] {
    margin-top: 3vh;
    width: 80px;
    background-color: black;
    color: white;
    font-size: 15px;
    font-weight: 600;
}


/* ************upload file********* */
.adminpanel{
    margin-bottom: 2vh;
    height: 105%;
}

.adminpanel form {
    margin: 2vh 0;
    padding: 3vh 0;
    color: black;
    font-weight: 600;
    background-color: rgba(255, 255, 255, 0.671);
    padding: 2vh;
    border-radius: 10px;
}

.content-type-ask select{
    margin-left: 2vh;
    width: 90px;
    height: 32px;
    border-radius: 5px;
}

.divds{
    margin-bottom: 2vh;
}

.new-series, .movie-add{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

}

.dd{
   width: 380px;

}

.divd{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 2vh;
}

.genres{
    display: grid;
    gap: 10px;
    grid-template-columns: 150px 150px 200px 160px;
    
}

.genre-option{
    margin-right: 3vh;
    display: flex;
    justify-content: space-between;

}

.selected-genre{
    padding-bottom: 3vh;
}

.selected-genre ul{
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.existing-series{
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 5vh;
}

.existing-series select{
    border-radius: 8px;
    padding: 0 1vh;
    height: 40px;
}

.existing-series div{
    display: grid;
    gap: 20px;
}

@media (max-width: 800px) {
    .genres{
        display: flex;
        flex-wrap: wrap;
    }
    .genre{
        margin-bottom: 2vh;
    }
    .genre-option{
        width: 135px;
        margin-right: 2vh;
    }
    .genre-option label{
        font-size: 12px;
    }
}


.adminpanel form input[type=submit]{
    height: 42px;
    width: 130px;
    cursor: pointer;
    font-weight: 600;
    letter-spacing: 1px;
    background-color: black;
    color: white;
    border-radius: 8px;
    font-size: 16px;
    border: none;
    outline: none;
}

.adminpanel form input[type=submit]:hover{
    scale: 1.1;
    transition: 0.4s;
    background-color: #333;
}