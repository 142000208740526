.mangas{
    min-height: 100%;
    width: 100%;
    padding-bottom: 5vh;
}

.manga{
    height: 100%;
    width: 100%;
    background-color: rgba(169, 168, 168, 0.542);
    border-radius: 10px;
    padding: 4vh 3vh;
}

.manga-bill-board{
    padding: 0 8vh;
}

.manga-box-board{
    margin-top: 5vh;
    background-color: black;
    display: flex;
    gap: 50px;
    padding: 3vh;
    border-radius: 10px;
}

.lt-manga img{
    height: 45vh;
    border-radius: 8px;
    box-shadow: 1px 1px 20px rgba(145, 141, 141, 0.778);
}

.title-eng{
    font-size: 80px;
    font-weight: 600;
    margin-left: -1vh;
}

.title-jap{
    font-size: 20px;
    margin-bottom: 2vh;
    color: rgb(216, 216, 216);
}

.description{
    color: #b5b5b5;
    margin-bottom: 2vh;
    -webkit-line-clamp: 5;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.btn-manga button{
    height: 40px;
    width: 120px;
    background-color: #5b5a5a;    
    border: none;
    border-radius: 10px;
    
    color: white;
    cursor: pointer;
}

.btn-manga button:hover{
    background-color: #b5b5b5;
    scale: 1.05;
    transition: 0.2s;
    color: black;
    box-shadow: 2px 1px 20px rgba(255, 255, 255, 0.493);
}

.sec2{
    margin: 10vh 0 0 0;
}

.title-list h1{
    color: #333;
}

.manga-boxes{
    margin: 5vh 0;
}

.manga-box{
    cursor: pointer;
    width: fit-content;
}

.box-img img{
    border-radius: 10px;
}

.manga-box p{
    padding-left: 4px;
    color: black;
    font-weight: 500;
}

























@media (max-width: 1100px) {
    .manga{
        padding: 4vh 0vh;
    }
    .title-eng{
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .manga-bill-board{
        padding: 0 1vh;
    }
}


@media (max-width: 800px) {
    .manga{
        background-color: transparent;
    }
    .manga-bill-board{
        padding: 0 0;
    }
    .manga-box-board{
        display: grid;
        gap: 5px;
    }
    .lt-manga img{
        height: 98%;
        width: 100%;
    }
    .title-eng{
        font-size: 35px;
        margin-left: 0;

    }
    .title-jap{
        font-size: 20px;

    }
    .description{
        font-size: 16px;
        -webkit-line-clamp: 3;
    }
}
